body {
  font-family: Arial, sans-serif;
  background-color: #dadada;
  margin: 0;
  padding: 0;
}

.admin-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
}

.admin-heading {
  font-size: 24px;
  margin-bottom: 20px;
  color: #515151;
}

.admin-subheading {
  font-size: 16px;
  margin-bottom: 20px;
  color: #515151;
}

.admin-button {
  background-color: #ff8a00;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  margin: 20px auto;
  display: block;
}

.admin-button:hover {
  background-color: #9e6c33;
}

.admin-button:active {
  background-color: #9e6c33;
}

.admin-search {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.table-container {
  display: flex;
  flex: 1;
  width: 100%;
  max-height: calc(100vh - 150px); /* Adjust as needed */
  overflow: auto; /* Allow scrolling */
}

.table-wrapper {
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
}

.admin-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  table-layout: auto;
}

.admin-table th, .admin-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  white-space: nowrap;
}

.admin-table th {
  background-color: #ff8a00;
  color: #515151;
}

.switch-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #ff8a00;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.main-dashboard-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.main-dashboard-overlay.active {
  display: block; 
}

.flyout-panel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 60%;
  width: auto;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease, right 0.3s ease;
  overflow-y: auto;
  z-index: 1000;
  opacity: 0; 
  visibility: hidden; 
}

.flyout-panel.open {
  right: 0;
  opacity: 1; 
  visibility: visible; 
}
